/* commons */
import {
  ACTION_OVERLAY_SHOW,
  ACTION_OVERLAY_HIDE
} from '@constants/actions.js';

const initialState = {
  hidden: true,
  hideSpinner: false
};

export const overlayReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTION_OVERLAY_SHOW: {
      return {
        hidden: false,
        hideSpinner: action.hideSpinner
      };
    }
    case ACTION_OVERLAY_HIDE: {
      return {
        hidden: true,
        hideSpinner: action.hideSpinner
      };
    }
    default:
      return state;
  }
};
