/* commons */
import { useState, useEffect } from 'react';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import {
  getOptionalField,
  validationProps,
  getErrors
} from '@utils/forms/index.js';
import { generateUuid } from '@utils/ui.js';
/* components */
import { Icon } from '@components/icon/index.js';
/* styles */
import './styles.css';

export const Password = ({
  name,
  value,
  label,
  placeholder,
  validations,
  onChange,
  reference,
  error,
  visibilityButton = true,
  strengthBar = false
}) => {
  const [currentValue, setCurrentValue] = useState(value || '');
  const [errorMessage, setErrorMessage] = useState(false);
  const [visible, setVisible] = useState(false);
  const uuid = generateUuid();

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    document.getElementById(uuid).value = currentValue;
  }, [currentValue, uuid]);

  const validate = (event) => {
    const value = event.target.value;
    const errors = getErrors(value, validations);
    !errors.length && setErrorMessage(false);
    errors.length && setErrorMessage(errors[0]);
    return !errors.length;
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setCurrentValue(value);
    if (reference?.onChange) {
      reference.onChange(event);
      return;
    }
    validate(event) && onChange && onChange(event);
  };

  const handleVisibilityClick = (event) => {
    event.preventDefault();
    setVisible(!visible);
  };

  return (
    <div
      className="component-password"
      data-recourse={RECOURSE_COMPONENT}
      data-error={!!errorMessage}
      data-label={!!label}
      data-filled={!!currentValue}
      data-visibility-button={!!visibilityButton}
      data-strength-bar={!!strengthBar}
    >
      {label ? <label htmlFor={uuid}>{label}</label> : ''}
      <div>
        <input
          type={visible ? 'text' : 'password'}
          id={uuid}
          name={getOptionalField(name)}
          placeholder={placeholder}
          {...reference}
          onChange={handleChange}
          minLength="20"
          maxLength="20"
          {...validationProps(validations)}
        />
        {errorMessage ? <div className="error">{errorMessage}</div> : ''}
        {visibilityButton ? (
          <a href="!#" onClick={handleVisibilityClick} className="icon">
            <Icon name={visible ? 'hidePassword' : 'showPassword'} />
          </a>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
