/* commons */
import { useState } from 'react';
import { store } from '@store/index.js';
import {
  NOTIFICATIONS_ADD,
  ACTIONS_USER_SESSION_ADD
} from '@constants/actions.js';
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from '@constants/notifications.js';
import { RECOURSE_STEP } from '@constants/recourses.js';
import { request } from '@utils/api.js';
/* endpoints */
import { endpointUserSessionPostV0 } from '@endpoints/user.session.post.v0.js';
/* components */
import { Form } from '@components/form/index.js';
import { Button } from '@components/button/index.js';
import { Link } from '@components/link/index.js';
/* messages */
import { getCommonMessages } from '@utils/messages.js';

const {
  forms: { fields: fieldsMessages }
} = getCommonMessages();

const model = [
  {
    component: 'input',
    name: 'email',
    label: fieldsMessages.email.label,
    validations: {
      required: true,
      email: true
    }
  },
  {
    component: 'password',
    name: 'password',
    label: fieldsMessages.password.label,
    validations: {
      required: true,
      password: true
    }
  },
  {
    component: 'captcha',
    name: 'captcha',
    label: fieldsMessages.captcha.label,
    validations: {
      required: true,
      captcha: true
    }
  }
];

export const StepLogin = ({ context, updateContext, goToNextStep }) => {
  const [disabledSubmitWarningSent, setDisabledSubmitWarningSent] =
    useState(false);
  const { messages } = context;

  const onSubmit = (values) => {
    request(endpointUserSessionPostV0, {
      payload: {
        email: values.email,
        password: values.password,
        captcha: values.captcha
      },
      headers: {
        captcha: context.captchaKey
      }
    })
      .then((response) => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.login.notifications.submitSuccess,
          status: NOTIFICATION_SUCCESS
        });
        if (response.status === 202) {
          updateContext(values);
          goToNextStep();
        } else {
          store.dispatch({
            type: ACTIONS_USER_SESSION_ADD
          });
        }
      })
      .catch((error) => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.login.notifications.submitError,
          status: NOTIFICATION_ERROR
        });
      });
  };

  const onDisabledSubmit = () => {
    if (!disabledSubmitWarningSent) {
      setDisabledSubmitWarningSent(true);
      store.dispatch({
        type: NOTIFICATIONS_ADD,
        message: messages.login.notifications.submitDisabled
      });
    }
  };

  return (
    <div className="step-login" data-recourse={RECOURSE_STEP}>
      <p>{messages.login.body}</p>
      <Form
        model={model}
        onSubmit={onSubmit}
        onDisabledSubmit={onDisabledSubmit}
        onChangeContext={updateContext}
      />
      <Button
        text={messages.login.links.recovery}
        icon="password"
        onClick="/recovery"
        type="cancel"
      />
      <Link text={messages.login.links.faq} onClick="/faq" />
    </div>
  );
};
