/* commons */
import { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { RECOURSE_LAYOUT } from '@constants/recourses.js';
import { store } from '@store/index.js';
/* layouts */
import { LayoutPublic } from '@layouts/public/index.js';
import { LayoutPrivate } from '@layouts/private/index.js';
/* public pages */
import { PageHome } from '@pages/public/home/index.js';
import { PageRecovery } from '@pages/public/recovery/index.js';
import { PageFaq as PageFaqPublic } from '@pages/public/faq/index.js';
/* private pages */
import { PageDashboard } from '@pages/private/dashboard/index.js';
/* styles */
import './styles.scss';

export const LayoutContainer = () => {
  const [logged, setLogged] = useState(store.getState().user.logged);

  store.subscribe((re) => {
    setLogged(store.getState().user.logged);
  });

  return (
    <div className="layout-container" data-recourse={RECOURSE_LAYOUT}>
      {!logged ? (
        <LayoutPublic>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={PageHome} />
              <Route exact path="/recovery" component={PageRecovery} />
              <Route exact path="/faq" component={PageFaqPublic} />
              <Route exact path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </BrowserRouter>
        </LayoutPublic>
      ) : (
        <LayoutPrivate>
          <BrowserRouter>
            <Switch>
              <Route exact path="/" component={PageDashboard} />
              <Route exact path="*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </BrowserRouter>
        </LayoutPrivate>
      )}
    </div>
  );
};
