/* commons */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { store } from '@store/index.js';
/* layouts */
import { LayoutContainer } from '@layouts/container/index.js';
/* components */
import { SilentNotifications } from '@components/silentNotifications/index.js';
import { Modals } from '@components/modals/index.js';
import { Overlay } from '@components/overlay/index.js';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <LayoutContainer />
      <Modals />
      <Overlay />
      <SilentNotifications />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
