/* commons */
import { RECOURSE_LAYOUT } from '@constants/recourses.js';
import { connect } from 'react-redux';
import { changeLanguage, getLanguage } from '@utils/language.js';
import { LANGUAGES_SPANISH, LANGUAGES_ENGLISH } from '@constants/languages.js';
/* components */
import { Link } from '@components/link/index.js';
/* messages */
import { messages } from '@layouts/_messages/index.js';
/* styles */
import './styles.scss';

const mapStateToProps = (state) => ({
  navigation: state.navigation
});

export const LayoutPublic = connect(mapStateToProps)(
  ({ children, navigation }) => {
    const {
      current: { title = {} }
    } = navigation;
    const currentLanguage = getLanguage();
    const languageButtonMessage =
      currentLanguage === LANGUAGES_SPANISH
        ? messages.languages.changeToEnglish
        : messages.languages.changeToSpanish;

    const handleChangeLanguage = () =>
      changeLanguage(
        currentLanguage === LANGUAGES_SPANISH
          ? LANGUAGES_ENGLISH
          : LANGUAGES_SPANISH
      );

    return (
      <div className="layout-public" data-recourse={RECOURSE_LAYOUT}>
        <div className="layout-public-top">
          <div className="layout-public-top__language-button">
            <Link
              text={languageButtonMessage}
              onClick={handleChangeLanguage}
              icon="language"
            />
          </div>
          <figure>
            <img src="/assets/images/mas-clinic-cloud.png" alt={messages.name} />
          </figure>
        </div>
        <div className="layout-public-bottom">
          {title.page ? (
            <div className="layout-public-bottom__title">
              <h1>{title.page}</h1>
            </div>
          ) : (
            ''
          )}
          <div className="layout-public-bottom__content">{children}</div>
        </div>
      </div>
    );
  }
);
