/* commons */
import { RECOURSE_LAYOUT } from '@constants/recourses.js';
/* styles */
import './styles.css';

export const LayoutPrivate = ({ children }) => {
  document.addEventListener('scroll', () => {
    const windowHeight = window.innerHeight;
    const scrollPosition = window.scrollY;
    const headerHeight = document.querySelector('.header').clientHeight || 0;
    const menuTopPosition = headerHeight;
    const menuHeight =
      document.querySelector('.menu > div > div').clientHeight || 0;
    const contentHeight =
      document.querySelector('.layout-private .content').clientHeight || 0;
    const menuFixed =
      contentHeight > menuHeight && scrollPosition > menuTopPosition;

    console.log('================================');
    console.log('================================');
    console.log('windowHeight ->', windowHeight);
    console.log('scrollPosition ->', scrollPosition);
    console.log('menuTopPosition ->', menuTopPosition);
    console.log('menuHeight ->', menuHeight);
    console.log('contentHeight ->', contentHeight);
    console.log('menuFixed ->', menuFixed);

    console.log(scrollPosition, menuHeight - windowHeight + headerHeight);
    if (
      menuFixed &&
      scrollPosition > menuHeight - windowHeight + headerHeight
    ) {
      console.log('1111111111');
      document.querySelector('.menu > div > div').style.position = 'fixed';
      document.querySelector('.menu > div > div').style.marginTop =
        '-' + (menuHeight - windowHeight) + 'px';
    } else if (menuFixed && menuHeight < windowHeight) {
      console.log('22222222222222');
      document.querySelector('.menu > div > div').style.position = 'fixed';
      document.querySelector('.menu > div > div').style.marginTop = '0px';
    } else {
      console.log('333333333');
      document.querySelector('.menu > div > div').style.position = 'static';
      document.querySelector('.menu > div > div').style.marginTop = '0px';
    }

    if (
      window.scrollY + window.innerHeight + 100 >
      document.querySelector('.menu div div').clientHeight + 1
    ) {
      //document.querySelector('.menu > div > div').style.marginTop = '-' + (document.querySelector('.menu div div').clientHeight-window.innerHeight) - 300 + 'px';
      //document.querySelector('.menu > div > div').style.position = 'fixed';
    } else {
      //document.querySelector('.menu > div > div').style.position = 'static';
      //document.querySelector('.menu > div > div').style.marginTop = '0px';
    }
  });

  return (
    <div className="layout-private" data-recourse={RECOURSE_LAYOUT}>
      <div className="header"></div>
      <div className="flex">
        <div className="menu">
          <div>
            <div>
              <img src="/menu.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  );
};
