/* commons */
import { connect } from 'react-redux';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
/* styles */
import './styles.css';

const mapStateToProps = (state) => ({
  hidden: state.overlay.hidden,
  hideSpinner: state.overlay.hideSpinner
});

export const Overlay = connect(mapStateToProps)(
  ({ hidden, hideSpinner = false }) => {
    return (
      <div
        className="component-overlay"
        data-recourse={RECOURSE_COMPONENT}
        data-hidden={!!hidden}
      >
        {!hideSpinner ? <span></span> : ''}
      </div>
    );
  }
);
