/* commons */
import { useState } from 'react';
import { store } from '@store/index.js';
import { NOTIFICATIONS_ADD } from '@constants/actions.js';
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from '@constants/notifications.js';
import { RECOURSE_STEP } from '@constants/recourses.js';
import { request } from '@utils/api.js';
/* endpoints */
import { endpointUserPasswordPutV0 } from '@endpoints/user.password.put.v0.js';
/* components */
import { Form } from '@components/form/index.js';
/* messages */
import { fieldsMessages } from '@utils/forms/index.js';

const model = [
  {
    component: 'input',
    name: 'email',
    label: fieldsMessages.email.label,
    placeholder: fieldsMessages.email.placeholder,
    validations: {
      required: true,
      email: true
    }
  },
  {
    component: 'captcha',
    name: 'captcha',
    label: fieldsMessages.captcha.label,
    placeholder: fieldsMessages.captcha.placeholder,
    validations: {
      required: true,
      captcha: true
    }
  }
];

export const StepSearchEmail = ({ context, updateContext, goToNextStep }) => {
  const [disabledSubmitWarningSent, setDisabledSubmitWarningSent] =
    useState(false);
  const { messages } = context;

  const onSubmit = (values) => {
    request(endpointUserPasswordPutV0, {
      payload: {
        email: values.email,
        captcha: values.captcha
      },
      headers: {
        captcha: context.captchaKey
      }
    })
      .then(() => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.searchEmail.notifications.submitSuccess,
          status: NOTIFICATION_SUCCESS
        });
        updateContext(values);
        goToNextStep();
      })
      .catch(() => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.searchEmail.notifications.submitError,
          status: NOTIFICATION_ERROR
        });
      });
  };

  const onDisabledSubmit = () => {
    if (!disabledSubmitWarningSent) {
      setDisabledSubmitWarningSent(true);
      store.dispatch({
        type: NOTIFICATIONS_ADD,
        message: messages.searchEmail.notifications.submitDisabled
      });
    }
  };

  return (
    <div className="step-search-email" data-recourse={RECOURSE_STEP}>
      <p>{messages.searchEmail.body}</p>
      <Form
        model={model}
        onSubmit={onSubmit}
        onDisabledSubmit={onDisabledSubmit}
        onChangeContext={updateContext}
        onCancel="/"
      />
    </div>
  );
};
