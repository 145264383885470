/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
/* components */
import { Expandable } from '@components/expandable/index.js';
/* styles */
import './styles.css';

export const ExpandableList = ({ autoClose, list }) => {
  return (
    <div
      className="component-expandable-list"
      data-recourse={RECOURSE_COMPONENT}
    >
      {list.map((expandable, index) => (
        <Expandable key={index} {...expandable} />
      ))}
    </div>
  );
};
