/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import { useHistory } from 'react-router-dom';
/* components */
import { Icon } from '@components/icon/index.js';
/* styles */
import './styles.css';

export const Button = (props) => {
  const history = useHistory();
  const text = props.text ? props.text : '';
  const onClick = props.onClick ? props.onClick : '#';
  const onDisabledClick = props.onDisabledClick;
  const type = props.type ? props.type : 'default';
  const fluid = props.fluid === true;
  const disabled = props.disabled === true;
  const icon = props.icon;

  const handleClick = (e) => {
    e.preventDefault();
    if (typeof onClick === 'string') {
      history.push(onClick);
    } else {
      onClick();
    }
  };

  const handleDisabledClick = (e) => {
    e.preventDefault();
    if (!onDisabledClick) {
      return;
    }
    onDisabledClick();
  };

  return (
    <div className="component-button" data-recourse={RECOURSE_COMPONENT}>
      <a
        href="!#"
        role="button"
        onClick={disabled ? handleDisabledClick : handleClick}
        data-type={type}
        data-fluid={fluid}
        data-disabled={disabled}
      >
        {icon ? (
          <div className="icon">
            <Icon name={icon} />
          </div>
        ) : (
          ''
        )}
        <div className="text">{text}</div>
      </a>
    </div>
  );
};
