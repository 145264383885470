/* commons */
import { getAllValidations } from '@utils/forms/validations.js';
/* messages */
import { getMessages } from './_messages/index.js';

export const fieldsMessages = getMessages()?.default?.fields || {};
export const errorsMessages = getMessages()?.default?.errors || {};
export const buttonsMessages = getMessages()?.default?.buttons || {};

export const getUseFormValidations = (
  validations = {},
  formValues,
  formContext = {}
) => {
  const useFormValidations = [
    'required',
    'maxLength',
    'minLength',
    'max',
    'min',
    'pattern'
  ];
  const allValidations = getAllValidations();
  return Object.keys(validations).reduce(
    (validationsOutput, validationKey) => {
      const isUseFormValidation = useFormValidations.includes(validationKey);
      const target = isUseFormValidation
        ? validationsOutput
        : validationsOutput.validate;
      target[validationKey] = isUseFormValidation
        ? validations[validationKey]
        : allValidations[validationKey].validate({
            validationValue: validations[validationKey],
            formValues,
            formContext
          });
      return validationsOutput;
    },
    { validate: {} }
  );
};

export const getOptionalField = (value) => {
  return value ? value : undefined;
};

export const getLabel = (label, validations = {}) => {
  const isRequired = validations.required;
  return label && isRequired ? `${label}*` : getOptionalField(label);
};

export const validationProps = (validations = {}) => {
  return {};
};

export const getErrors = (value, validations) => {
  return ['error particulaaaaaar', 2];
};

const captchaCodes = {
  '10c46bc317802bcb21da59e9f6836c65': '6805f690c859c13eeee8782bf39d6f12',
  '80c6d2cfc655446b51397795e7dd6a8d': '9e3f07cef5fe77d2eaf4c97e21fd3b75',
  b60ee1b3290879ed66d268aa78aefe72: 'f44c07726bfa00406b872aa6b31f176c',
  c358567a45102c1fae43f8abbfe3affc: 'fd1fe6f0383b4c5ca09ca1a3097b7831',
  c9cfe64655ba180be63861c2ca161e21: '2b0081074f65885944c489a9b594d887',
  '6d04c0d7829cdf4b340e4bcac3262568': 'bd67a4112cdda7147a91e01c81e96c18',
  c98749fbaf40fcb889129acbf1aafc65: '703878d6ce017d3700581ae12d0ed5b3',
  d1435ea4e045e30f1c8c21b2756709fb: '905ff503b8b0755cecf09c1bbb00a761',
  de83b3df52df6e48cfe3566167ecd52d: '06bf7127d70359f5dca75b3d2e2b4cf9',
  '0b7053e167cc052ac08b157898055035': '768af208fd45964e99d1fa994521be06'
};

export const getCaptchaKey = () => {
  const allCaptchaKeys = Object.keys(captchaCodes);
  const randomNumber = Math.random() * allCaptchaKeys.length;
  const randomIndex = Math.floor(randomNumber);
  return allCaptchaKeys[randomIndex];
};
