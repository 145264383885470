/* commons */
import {
  ACTIONS_USER_SESSION_ADD,
  USER_LOGOUT,
  ACTIONS_USER_LANGUAGE_CHANGE
} from '@constants/actions.js';
import {
  LANGUAGES_DEFAULT,
  LANGUAGES_AVAILABLE
} from '@constants/languages.js';

const initialState = {
  logged: false,
  language: LANGUAGES_DEFAULT,
  session: null
};

export const userReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ACTIONS_USER_SESSION_ADD: {
      return {
        ...state,
        logged: true
      };
    }
    case USER_LOGOUT: {
      return {
        ...state,
        logged: false
      };
    }
    case ACTIONS_USER_LANGUAGE_CHANGE: {
      return {
        ...state,
        language: LANGUAGES_AVAILABLE.includes(action.language)
          ? action.language
          : LANGUAGES_DEFAULT
      };
    }
    default:
      return state;
  }
};
