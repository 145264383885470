/* commons */
import { store } from '@store/index.js';
import {
  ACTION_OVERLAY_SHOW,
  ACTION_OVERLAY_HIDE
} from '@constants/actions.js';

export const showOverlay = () => {
  store.dispatch({
    type: ACTION_OVERLAY_SHOW
  });
};

export const hideOverlay = () => {
  store.dispatch({
    type: ACTION_OVERLAY_HIDE
  });
};

export const generateUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r & 0x3 || 0x8;
    return v.toString(16);
  });
};
