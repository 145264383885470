/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import {
  faArrowRightToBracket,
  faXmark,
  faAngleRight,
  faKey,
  faCheck,
  faAngleDown,
  faAngleUp,
  faAngleLeft,
  faCircleExclamation,
  faEye,
  faEyeSlash,
  faGlobe
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* styles */
import './styles.css';

const icons = {
  access: faArrowRightToBracket,
  cancel: faXmark,
  continue: faAngleRight,
  password: faKey,
  check: faCheck,
  arrowDown: faAngleDown,
  arrowUp: faAngleUp,
  arrowLeft: faAngleLeft,
  arrowRight: faAngleRight,
  warning: faCircleExclamation,
  hidePassword: faEyeSlash,
  showPassword: faEye,
  language: faGlobe
};

export const Icon = ({ name }) => {
  return (
    <div className="component-icon" data-recourse={RECOURSE_COMPONENT}>
      <FontAwesomeIcon icon={icons[name]} />
    </div>
  );
};
