/* commons */
import { useState } from 'react';
import { RECOURSE_PAGE } from '@constants/recourses.js';
/* components */
import { Multistep } from '@components/multistep/index.js';
/* steps */
import { StepLogin } from './_steps/login.js';
import { StepVerification } from './_steps/verification.js';
/* messages */
import { messages } from './_messages/index.js';

const steps = [
  {
    page: messages.title,
    title: messages.login.progressTitle,
    component: StepLogin
  },
  {
    page: messages.title,
    title: messages.verification.progressTitle,
    component: StepVerification
  }
];

export const PageHome = () => {
  const [context, setContext] = useState({
    messages
  });

  return (
    <div className="page-home" data-recourse={RECOURSE_PAGE}>
      <Multistep
        context={context}
        setContext={setContext}
        steps={steps}
        showProgress={false}
        showProgressTitle={false}
      />
    </div>
  );
};
