/* commons */
import { showOverlay, hideOverlay } from '@utils/ui.js';
import {
  API_HEADER_PREFIX,
  API_HOST_LOCAL,
  API_HOST,
  API_REST_PATH
} from '@constants/api.js';
import { getLanguage } from '@utils/language.js';

export const request = (endpoint = {}, options = {}) => {
  endpoint.url = getUrl(endpoint.path, options);
  const normalizedOptions = normalizeOptions(options);
  onBeforeRequest(endpoint, normalizedOptions);
  return fetch(endpoint.url, getFetchData(endpoint, options))
    .then(async (response) => {
      let body = null;
      try {
        body = await response.json();
      } catch (e) {}
      const normalizedResponse = {
        body: body,
        headers: getResponseHeaders(response),
        status: response.status
      };
      return response.ok
        ? normalizedResponse
        : Promise.reject(normalizedResponse);
    })
    .then((response) => onSuccess(response, endpoint, normalizedOptions))
    .catch((response) => onError(response, endpoint, normalizedOptions))
    .finally(() => onAfterRequest(endpoint, normalizedOptions));
};

const getResponseHeaders = (response) => {
  const headers = {};
  for (let entry of response.headers.entries()) {
    headers[entry[0]] = entry[1];
  }
  return headers;
};

const normalizeOptions = (options) => {
  return {
    showOverlay: true,
    saveError: true,
    ...options
  };
};

const getUrl = (url, options = {}) => {
  const host =
    window.location.host === 'localhost:3000' ? API_HOST_LOCAL : API_HOST;
  //const { queryParams = {}, uriParams = {} } = options;
  return `${host}/${API_REST_PATH}/${url}/`;
};

const getFetchData = (endpoint = {}, options = {}) => {
  return {
    method: endpoint.method,
    headers: getHeaders(endpoint, options),
    body: options.payload ? JSON.stringify(options.payload) : undefined
  };
};

const getHeaders = (endpoint = {}, options = {}) => {
  const { headers = {} } = options;
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Connection: 'keep-alive',
    [`${API_HEADER_PREFIX}-language`]: getLanguage(),
    [`${API_HEADER_PREFIX}-identifier`]: endpoint.identifier,
    ...getAuthorizationHeader(endpoint),
    ...headers
  };
};

const getAuthorizationHeader = (endpoint = {}) => {
  return endpoint.public
    ? {}
    : {
        [`${API_HEADER_PREFIX}-session`]: `gsdfgg-2345-fds-g34-56235625`
      };
};

const onBeforeRequest = (endpoint, options = {}) => {
  if (options.onBeforeRequest) {
    options.onBeforeRequest(endpoint, options);
  }
  options.showOverlay && showOverlay();
};

const onSuccess = (response, endpoint, options = {}) => {
  if (options.onSuccess) {
    options.onSuccess(response, endpoint, options);
  }
  return response;
};

const onError = (response, endpoint, options = {}) => {
  if (options.onError) {
    options.onError(response, endpoint, options);
  }
  if (options.saveError) {
    // save error
  }
  return Promise.reject(response);
};

const onAfterRequest = (endpoint, options = {}) => {
  if (options.onAfterRequest) {
    options.onAfterRequest(endpoint, options);
  }
  options.showOverlay && hideOverlay();
};
