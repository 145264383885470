/* commons */
import { useState } from 'react';
import { RECOURSE_PAGE } from '@constants/recourses.js';
/* components */
import { Multistep } from '@components/multistep/index.js';
/* steps */
import { StepSearchEmail } from './_steps/searchEmail.js';
import { StepConfirmCode } from './_steps/confirmCode.js';
import { StepResetPassword } from './_steps/resetPassword.js';
import { StepFinal } from './_steps/final.js';
/* messages */
import { messages } from './_messages/index.js';
/* styles */
import './style.scss';

const steps = [
  {
    page: messages.title,
    title: messages.searchEmail.progressTitle,
    component: StepSearchEmail
  },
  {
    page: messages.title,
    title: messages.confirmCode.progressTitle,
    component: StepConfirmCode
  },
  {
    page: messages.title,
    title: messages.resetPassword.progressTitle,
    component: StepResetPassword
  },
  {
    page: messages.title,
    component: StepFinal,
    computed: false
  }
];

export const PageRecovery = () => {
  const [context, setContext] = useState({
    messages
  });

  return (
    <div className="page-recovery" data-recourse={RECOURSE_PAGE}>
      <Multistep
        context={context}
        setContext={setContext}
        steps={steps}
        showProgress={true}
        showProgressTitle={true}
      />
    </div>
  );
};
