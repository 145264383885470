/* commons */
import { useState, useEffect } from 'react';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import {
  getOptionalField,
  validationProps,
  getErrors
} from '@utils/forms/index.js';
import { generateUuid } from '@utils/ui.js';
/* styles */
import './styles.css';

export const Input = ({
  type = 'text',
  name,
  value,
  label,
  placeholder,
  validations,
  onChange,
  reference,
  error
}) => {
  const [currentValue, setCurrentValue] = useState(value || '');
  const [errorMessage, setErrorMessage] = useState(false);
  const uuid = generateUuid();

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  const validate = (event) => {
    const value = event.target.value;
    const errors = getErrors(value, validations);
    !errors.length && setErrorMessage(false);
    errors.length && setErrorMessage(errors[0]);
    return !errors.length;
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setCurrentValue(value);
    if (reference?.onChange) {
      reference.onChange(event);
      return;
    }
    validate(event) && onChange && onChange(event);
  };

  return (
    <div
      className="component-input"
      data-recourse={RECOURSE_COMPONENT}
      data-error={!!errorMessage}
      data-label={!!label}
      data-filled={!!currentValue}
    >
      {label ? <label htmlFor={uuid}>{label}</label> : ''}
      <div>
        <input
          type={type}
          id={uuid}
          name={getOptionalField(name)}
          value={currentValue}
          placeholder={placeholder}
          {...reference}
          onChange={handleChange}
          {...validationProps(validations)}
        />
        {errorMessage ? <div className="error">{errorMessage}</div> : ''}
      </div>
    </div>
  );
};
