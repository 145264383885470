/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
/* components */
import { Icon } from '@components/icon/index.js';
/* styles */
import './style.css';

export const MultistepProgress = ({ steps, currentStep, showTitle }) => {
  const getTitle = (title) => {
    if (!showTitle || !title) {
      return;
    }
    return <div className="title">{title}</div>;
  };

  const stepFragments = steps.map((step, index) => (
    <div
      key={index}
      className="step"
      data-current={index === currentStep}
      data-done={index < currentStep}
    >
      <div>
        <div className="bar">
          <span></span>
        </div>
        <div className="badget">
          <span>{index < currentStep ? <Icon name="check" /> : index + 1}</span>
        </div>
      </div>
      {getTitle(step.progressTitle || step.title)}
    </div>
  ));

  return (
    <div
      className="component-multistep-progress"
      data-recourse={RECOURSE_COMPONENT}
    >
      <div>{stepFragments}</div>
    </div>
  );
};
