/* commons */
import { useState } from 'react';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import { useForm } from 'react-hook-form';
import {
  getUseFormValidations,
  getOptionalField,
  getLabel
} from '@utils/forms/index.js';
import { getAllValidations } from '@utils//forms/validations.js';
/* components */
import { Button } from '@components/button/index.js';
import { Input } from '@components/input/index.js';
import { Password } from '@components/password/index.js';
import { Captcha } from '@components/captcha/index.js';
/* messages */
import { buttonsMessages } from '@utils/forms/index.js';

export const Form = ({
  model,
  onSubmit,
  onDisabledSubmit,
  onCancel,
  onChangeContext
}) => {
  const { register, handleSubmit, formState, getValues } = useForm({
    mode: 'onChange'
  });
  const { errors, isValid } = formState;
  const [formContext, setFormContext] = useState({});

  const updateFormContext = (values) => {
    if (typeof values !== 'object') {
      return;
    }
    setFormContext({
      ...formContext,
      ...values
    });
    onChangeContext && onChangeContext(values);
  };

  const getError = (name) => {
    const errorType = errors[name]?.type;
    if (!errorType) {
      return false;
    }
    const allValidations = getAllValidations();
    const field = model.find((model) => model.name === name) || {};
    const errorMessage =
      field?.errors?.[errorType] ||
      allValidations[errorType]?.message ||
      errorType;
    return errorMessage.replace(
      '{value}',
      field.validations?.[errorType] || ''
    );
  };

  return (
    <div className="component-form" data-recourse={RECOURSE_COMPONENT}>
      <form onSubmit={handleSubmit(onSubmit, onDisabledSubmit)}>
        {
          // eslint-disable-next-line array-callback-return
          model.map((field, index) => {
            const commonProps = {
              key: index,
              id: getOptionalField(field.id),
              name: getOptionalField(field.name),
              value: getOptionalField(field.value),
              label: getLabel(field.label, field.validations),
              placeholder: getOptionalField(field.placeholder),
              error: getError(field.name),
              formContext,
              updateFormContext,
              reference: register(field.name, {
                ...getUseFormValidations(
                  field.validations,
                  getValues(),
                  formContext
                ),
                value: field.value || ''
              })
            };
            switch (field.component) {
              case 'input':
                return <Input {...commonProps} />;
              case 'password':
                return <Password {...commonProps} />;
              case 'captcha':
                return <Captcha {...commonProps} />;
              default:
                break;
            }
          })
        }
        <div className="buttons">
          <Button
            text={buttonsMessages.continue}
            icon="arrowRight"
            onClick={handleSubmit(onSubmit)}
            onDisabledClick={onDisabledSubmit}
            disabled={!isValid}
          />
          {onCancel ? (
            <Button
              text={buttonsMessages.cancel}
              icon="arrowLeft"
              onClick={onCancel}
              type="cancel"
            />
          ) : (
            ''
          )}
        </div>
      </form>
    </div>
  );
};
