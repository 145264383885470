export const NAVIGATION_ADD = 'NAVIGATION_ADD';
export const NOTIFICATIONS_ADD = 'NOTIFICATIONS_ADD';
export const NOTIFICATIONS_REMOVE = 'NOTIFICATIONS_REMOVE';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';

export const ACTION_OVERLAY_SHOW = 'ACTION_OVERLAY_SHOW';
export const ACTION_OVERLAY_HIDE = 'ACTION_OVERLAY_HIDE';

export const ACTION_PAGE_UPDATE = 'ACTION_PAGE_UPDATE';

export const ACTIONS_USER_SESSION_ADD = 'ACTIONS_USER_SESSION_ADD';
export const ACTIONS_USER_LANGUAGE_CHANGE = 'ACTIONS_USER_LANGUAGE_CHANGE';
