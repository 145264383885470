/* commons */
import { useState } from 'react';
import { store } from '@store/index.js';
import { NOTIFICATIONS_ADD } from '@constants/actions.js';
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from '@constants/notifications.js';
import { RECOURSE_STEP } from '@constants/recourses.js';
import { request } from '@utils/api.js';
/* endpoints */
import { endpointUserPasswordPutV0 } from '@endpoints/user.password.put.v0.js';
/* components */
import { Form } from '@components/form/index.js';

const getModel = (messages) => [
  {
    component: 'input',
    name: 'password',
    label: messages.resetPassword.fields.newPassword.label,
    placeholder: messages.resetPassword.fields.newPassword.placeholder,
    validations: {
      required: true,
      password: true
    },
    props: {
      strongBar: true
    }
  },
  {
    component: 'input',
    name: 'repassword',
    label: messages.resetPassword.fields.repeatNewPassword.label,
    placeholder: messages.resetPassword.fields.repeatNewPassword.placeholder,
    validations: {
      required: true,
      password: true,
      equalTo: 'password'
    },
    errors: {
      equalTo: messages.resetPassword.fields.repeatNewPassword.errors.equalTo
    }
  }
];

export const StepResetPassword = ({ context, updateContext, goToNextStep }) => {
  const [disabledSubmitWarningSent, setDisabledSubmitWarningSent] =
    useState(false);
  const { messages } = context;
  const model = getModel(messages);

  const onSubmit = (values) => {
    request(endpointUserPasswordPutV0, {
      payload: {
        email: context.email,
        captcha: context.captcha,
        code: context.code,
        password: values.password,
        repassword: values.repassword
      },
      headers: {
        captcha: context.captchaKey
      }
    })
      .then(() => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.confirmCode.notifications.submitSuccess,
          status: NOTIFICATION_SUCCESS
        });
        updateContext(values);
        goToNextStep();
      })
      .catch(() => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.confirmCode.notifications.submitError,
          status: NOTIFICATION_ERROR
        });
      });
  };

  const onDisabledSubmit = () => {
    if (!disabledSubmitWarningSent) {
      setDisabledSubmitWarningSent(true);
      store.dispatch({
        type: NOTIFICATIONS_ADD,
        message: messages.resetPassword.notifications.submitDisabled
      });
    }
  };

  return (
    <div className="step-reset-password" data-recourse={RECOURSE_STEP}>
      <p>{messages.resetPassword.body}</p>
      <Form
        model={model}
        onSubmit={onSubmit}
        onDisabledSubmit={onDisabledSubmit}
        onChangeContext={updateContext}
        onCancel="/"
      />
    </div>
  );
};
