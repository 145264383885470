/* commons */
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '@store/index.js';
import { NOTIFICATIONS_REMOVE } from '@constants/actions.js';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
/* components */
import { Icon } from '@components/icon/index.js';
/* styles */
import './styles.css';

const COMPONENT_CLASS = `component-silent-notifications`;
const NOTIFICATION_INTERVAL = 5000;
const NOTIFICATION_TRANSITION_DURATION = 500;

const mapStateToProps = (state) => ({
  notification: state.notifications.queue.find(
    (notification) => notification.id === state.notifications.current
  )
});

const icons = {
  error: 'cancel',
  warning: 'warning',
  success: 'check'
};

export const SilentNotifications = connect(mapStateToProps)(
  ({ notification }) => {
    const changeNotificationVisibility = (hidden, interval = 0) => {
      return new Promise((resolve) =>
        setTimeout(() => {
          document
            .querySelector(`.${COMPONENT_CLASS}`)
            .querySelector('div')
            .setAttribute('data-hidden', hidden);
          resolve();
        }, interval)
      );
    };

    const removeNotification = (id) => {
      return new Promise((resolve) =>
        setTimeout(() => {
          store.dispatch({ type: NOTIFICATIONS_REMOVE, id });
          resolve();
        }, NOTIFICATION_TRANSITION_DURATION)
      );
    };

    useEffect(() => {
      if (!notification) {
        return;
      }
      changeNotificationVisibility(false)
        .then(() =>
          changeNotificationVisibility(
            true,
            NOTIFICATION_INTERVAL + NOTIFICATION_TRANSITION_DURATION
          )
        )
        .then(() => removeNotification(notification.id));
    });

    return (
      <div
        className={COMPONENT_CLASS}
        data-recourse={RECOURSE_COMPONENT}
        data-hidden={!notification}
      >
        {notification && (
          <div data-status={notification.status} data-hidden={true}>
            <div className="icon">
              <Icon name={icons[notification.status]} />
            </div>
            <p>{notification.message}</p>
          </div>
        )}
      </div>
    );
  }
);
