/* commons */
import { NAVIGATION_ADD } from '@constants/actions.js';
import { updateDocumentTitle } from '@utils/accessibility.js';
import { addEvent } from '@utils/datalayer.js';

const initialState = {
  current: {
    title: {
      module: null,
      page: null,
      step: null
    },
    path: null
  },
  history: []
};

export const navigationReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NAVIGATION_ADD: {
      const title =
        typeof action.title === 'string'
          ? {
              page: action.title
            }
          : {
              module: action.module ? action.module : null,
              page: action.page ? action.page : null,
              step: action.step ? action.step : null
            };
      const current = { title, path: window.location.pathname };
      updateDocumentTitle(title);
      addEvent(current);
      return {
        current,
        history: [
          ...state.history, //borrar viejas
          current
        ]
      };
    }
    default:
      return state;
  }
};
