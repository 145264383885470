/* commons */
import { NOTIFICATIONS_ADD, NOTIFICATIONS_REMOVE } from '@constants/actions.js';
import { generateUuid } from '@utils/ui.js';
import { NOTIFICATION_WARNING } from '@constants/notifications.js';

const initialState = {
  queue: [],
  current: null
};

export const notificationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case NOTIFICATIONS_ADD: {
      const id = generateUuid();
      return {
        current: state.current ? state.current : id,
        queue: [
          ...state.queue,
          {
            id: id,
            message: action.message,
            status: action.status ? action.status : NOTIFICATION_WARNING
          }
        ]
      };
    }
    case NOTIFICATIONS_REMOVE: {
      const queue = state.queue.filter(
        (notification) => notification.id !== action.id
      );
      return {
        current: queue[0] ? queue[0].id : null,
        queue
      };
    }
    default:
      return state;
  }
};
