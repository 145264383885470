/* messages */
import { messages } from '@layouts/_messages/index.js';

export const updateDocumentTitle = (params) => {
  document.title = [
    params.step,
    params.page,
    params.module,
    params.app || messages.name
  ]
    .filter((fragment) => !!fragment)
    .join(' | ');
};
