/* commons */
import { setCookie, getCookie } from '@utils/cookies.js';
import {
  LANGUAGES_COOKIE,
  LANGUAGES_DEFAULT,
  LANGUAGES_AVAILABLE
} from '@constants/languages.js';

export const changeLanguage = (language = LANGUAGES_DEFAULT) => {
  const currentLanguage = getLanguage();
  setCookie(LANGUAGES_COOKIE, language);
  currentLanguage !== getLanguage() && window.location.reload();
};

export const getLanguage = () => {
  const languageFromCookie = getCookie(LANGUAGES_COOKIE);
  return LANGUAGES_AVAILABLE.includes(languageFromCookie)
    ? languageFromCookie
    : LANGUAGES_DEFAULT;
};
