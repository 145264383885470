/* commons */
import { useState } from 'react';
import { store } from '@store/index.js';
import {
  NOTIFICATIONS_ADD,
  ACTIONS_USER_SESSION_ADD
} from '@constants/actions.js';
import {
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR
} from '@constants/notifications.js';
import { RECOURSE_STEP } from '@constants/recourses.js';
import { request } from '@utils/api.js';
/* endpoints */
import { endpointUserSessionPostV0 } from '@endpoints/user.session.post.v0.js';
/* components */
import { Form } from '@components/form/index.js';

const getModel = (messages) => [
  {
    component: 'input',
    name: 'code',
    label: messages.verification.fields.code.label,
    validations: {
      required: true
    }
  }
];

export const StepVerification = ({
  context,
  goToPreviousStep,
  goToNextStep
}) => {
  const { messages } = context;
  const [disabledSubmitWarningSent, setDisabledSubmitWarningSent] =
    useState(false);
  const model = getModel(messages);

  const onSubmit = (values) => {
    request(endpointUserSessionPostV0, {
      payload: {
        email: context.email,
        password: context.password,
        captcha: context.captcha,
        code: values.code
      },
      headers: {
        captcha: context.captchaKey
      }
    })
      .then(() => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.verification.notifications.submitSuccess,
          status: NOTIFICATION_SUCCESS
        });
        store.dispatch({
          type: ACTIONS_USER_SESSION_ADD
        });
      })
      .catch(() => {
        store.dispatch({
          type: NOTIFICATIONS_ADD,
          message: messages.login.notifications.submitError,
          status: NOTIFICATION_ERROR
        });
      });
  };

  const onDisabledSubmit = () => {
    if (!disabledSubmitWarningSent) {
      setDisabledSubmitWarningSent(true);
      store.dispatch({
        type: NOTIFICATIONS_ADD,
        message: messages.verification.notifications.submitDisabled
      });
    }
  };

  return (
    <div className="step-verification" data-recourse={RECOURSE_STEP}>
      <p>{messages.verification.body}</p>
      <Form
        model={model}
        onSubmit={onSubmit}
        onDisabledSubmit={onDisabledSubmit}
        onCancel={goToPreviousStep}
      />
    </div>
  );
};
