/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import { useState, useEffect } from 'react';
import { store } from '@store/index.js';
import { NAVIGATION_ADD } from '@constants/actions.js';
/* components */
import { MultistepProgress } from '../multistepProgress/index.js';

export const Multistep = ({
  context = {},
  setContext,
  steps,
  showProgress,
  showProgressTitle
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [multistepContext, setMultistepContext] = useState(context);
  const computedSteps = steps.filter((step) => step.computed !== false);
  let CurrentStepComponent = steps[currentStep].component;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    CurrentStepComponent = steps[currentStep].component;
    const page = steps[currentStep].page || context.title?.page || '';
    if (page) {
      store.dispatch({
        type: NAVIGATION_ADD,
        module: steps[currentStep].module || context.title?.module || '',
        page,
        step: steps[currentStep].title
      });
    }
  }, [currentStep]);

  useEffect(() => {
    setContext && setContext(multistepContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multistepContext]);

  const updateContext = (values) => {
    if (typeof values !== 'object') {
      return;
    }
    setMultistepContext({
      ...context,
      ...values
    });
  };

  const goToPreviousStep = () => {
    if (currentStep === 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const goToNextStep = () => {
    if (currentStep === steps.length) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  return (
    <div className="component-multistep" data-recourse={RECOURSE_COMPONENT}>
      {showProgress ? (
        <MultistepProgress
          steps={computedSteps}
          currentStep={currentStep}
          showTitle={showProgressTitle}
        />
      ) : (
        ''
      )}
      <CurrentStepComponent
        context={context || multistepContext}
        updateContext={updateContext}
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
      />
    </div>
  );
};
