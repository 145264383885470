/* commons */
import { combineReducers } from 'redux';
import { notificationsReducer } from './notifications.js';
import { overlayReducer } from './overlay.js';
import { navigationReducer } from './navigation.js';
import { userReducer } from './user.js';

export const reducers = combineReducers({
  notifications: notificationsReducer,
  overlay: overlayReducer,
  navigation: navigationReducer,
  user: userReducer
});
