/* commons */
import { useState, useEffect } from 'react';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import { VALIDATION_CAPTCHA_LENGTH } from '@constants/validations.js';
import {
  getOptionalField,
  validationProps,
  getErrors,
  getCaptchaKey
} from '@utils/forms/index.js';
import { generateUuid } from '@utils/ui.js';
/* styles */
import './styles.css';

export const Captcha = ({
  name,
  label,
  placeholder,
  validations,
  onChange,
  reference,
  error,
  updateFormContext
}) => {
  const [currentValue, setCurrentValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [captchaKey] = useState(getCaptchaKey());
  const uuid = generateUuid();

  useEffect(() => {
    setErrorMessage(error);
  }, [error]);

  useEffect(() => {
    updateFormContext && updateFormContext({ captchaKey });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [captchaKey]);

  const validate = (event) => {
    const value = event.target.value;
    const errors = getErrors(value, validations);
    !errors.length && setErrorMessage(false);
    errors.length && setErrorMessage(errors[0]);
    return !errors.length;
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setCurrentValue(value);
    if (reference?.onChange) {
      reference.onChange(event);
      return;
    }
    validate(event) && onChange && onChange(event);
  };

  return (
    <div
      className="component-captcha"
      data-recourse={RECOURSE_COMPONENT}
      data-error={!!errorMessage}
      data-label={!!label}
      data-filled={!!currentValue}
      data-captcha={captchaKey}
    >
      {label ? <label htmlFor={uuid}>{label}</label> : ''}
      <div>
        <div>
          <input
            type="text"
            id={uuid}
            value={currentValue}
            name={getOptionalField(name)}
            placeholder={placeholder}
            {...reference}
            onChange={handleChange}
            minLength={VALIDATION_CAPTCHA_LENGTH}
            maxLength={VALIDATION_CAPTCHA_LENGTH}
            {...validationProps(validations)}
          />
          <figure>
            <img src={`/assets/images/captcha/${captchaKey}.png`} alt="" />
          </figure>
        </div>
        {errorMessage ? <div className="error">{errorMessage}</div> : ''}
      </div>
    </div>
  );
};
