/* commons */
import { useState } from 'react';
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
/* components */
import { Icon } from '@components/icon/index.js';
/* styles */
import './styles.css';

export const Expandable = ({ title, subtitle, content, expanded = false }) => {
  const [isExpanded, setIsExpanded] = useState(expanded);

  const onClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className="component-expandable"
      data-recourse={RECOURSE_COMPONENT}
      data-expanded={isExpanded}
    >
      <div className="header" onClick={onClick}>
        <div>
          <div className="title">{title}</div>
          {subtitle ? <div className="subtitle">{subtitle}</div> : ''}
        </div>
        <div className="icon">
          <Icon name={isExpanded ? 'arrowUp' : 'arrowDown'} />
        </div>
      </div>
      <div className="content">
        <div>
          <p dangerouslySetInnerHTML={{ __html: content }}></p>
        </div>
      </div>
    </div>
  );
};
