/* commons */
import { LANGUAGES_DEFAULT } from '@constants/languages.js';
import { getLanguage } from '@utils/language.js';
/* messages */
import { messages } from '@layouts/_messages/index.js';

export const getMessages = (messages) => (language) =>
  messages[language] || messages[getLanguage()] || messages[LANGUAGES_DEFAULT];

export const getCommonMessages = () => messages;
