/* commons */
import { RECOURSE_STEP } from '@constants/recourses.js';
/* components */
import { Button } from '@components/button/index.js';

export const StepFinal = ({ context, updateContext, goToNextStep }) => {
  const { messages } = context;

  return (
    <div className="step-final" data-recourse={RECOURSE_STEP}>
      <p>{messages.final.body}</p>
      <Button text={messages.final.buttons.returnToLogin} onClick="/" />
    </div>
  );
};
