/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
import { useHistory } from 'react-router-dom';
/* components */
import { Icon } from '@components/icon/index.js';
/* styles */
import './styles.css';

export const Link = ({ text, onClick, icon }) => {
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    if (typeof onClick === 'string') {
      history.push(onClick);
    } else {
      onClick();
    }
  };

  return (
    <div className="component-link" data-recourse={RECOURSE_COMPONENT}>
      <a href="!#" onClick={handleClick}>
        {icon ? (
          <span className="icon">
            <Icon name={icon} />
          </span>
        ) : (
          ''
        )}
        <span className="text">{text}</span>
      </a>
    </div>
  );
};
