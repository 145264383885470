/* commons */
import { RECOURSE_COMPONENT } from '@constants/recourses.js';
/* styles */
import './styles.css';

export const Modals = () => {
  return (
    <div className="component-modals" data-recourse={RECOURSE_COMPONENT}></div>
  );
};
