/* commons */
import md5 from 'md5';
import {
  VALIDATION_CAPTCHA_LENGTH,
  VALIDATION_PASSWORD_MIN_LENGTH,
  VALIDATION_PASSWORD_MAX_LENGTH
} from '@constants/validations.js';
/* messages */
import { getMessages } from './_messages/index.js';

export const isRequired = () => (value) => {
  return String(value).length;
};

export const isEmail = () => (value) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(String(value).toLowerCase());
};

export const isCaptcha =
  ({ formContext }) =>
  (value) => {
    return (
      String(value).length === VALIDATION_CAPTCHA_LENGTH &&
      md5(String(value).toLowerCase()) === formContext.captchaKey
    );
  };

export const isPassword = () => (value) => {
  const regex = new RegExp(
    `^([a-zA-Z0-9-_]+){${VALIDATION_PASSWORD_MIN_LENGTH},${VALIDATION_PASSWORD_MAX_LENGTH}}$`,
    'g'
  );
  return regex.test(String(value));
};

export const isEqualTo =
  ({ validationValue, formValues }) =>
  (value) =>
    String(value) === String(formValues[validationValue]);

export const getAllValidations = () => {
  const messages = getMessages();
  return {
    required: {
      validate: isRequired,
      message: messages.default.errors.required
    },
    email: {
      validate: isEmail,
      message: messages.default.errors.email
    },
    captcha: {
      validate: isCaptcha,
      message: messages.default.errors.captcha
    },
    password: {
      validate: isPassword,
      message: messages.default.errors.password
    },
    maxLength: {
      validate: null,
      message: messages.default.errors.maxLength
    },
    minLength: {
      validate: null,
      message: messages.default.errors.minLength
    },
    max: {
      validate: null,
      message: messages.default.errors.max
    },
    min: {
      validate: null,
      message: messages.default.errors.min
    },
    pattern: {
      validate: null,
      message: messages.default.errors.pattern
    },
    equalTo: {
      validate: isEqualTo,
      message: messages.default.errors.isEqual
    }
  };
};
