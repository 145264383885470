/* commons */
import { useEffect } from 'react';
import { RECOURSE_PAGE } from '@constants/recourses.js';
import { store } from '@store/index.js';
import { NAVIGATION_ADD } from '@constants/actions.js';
/* components */
import { ExpandableList } from '@components/expandableList/index.js';
import { Button } from '@components/button/index.js';
/* messages */
import { messages } from './_messages/index.js';

const list = [
  messages.expandableList.registerCenter,
  messages.expandableList.registerUser,
  messages.expandableList.forgottenPassword,
  messages.expandableList.authorizationCodeNotReceived
];

export const PageFaq = () => {
  useEffect(() => {
    store.dispatch({
      type: NAVIGATION_ADD,
      title: messages.title
    });
  });

  return (
    <div className="page-faq" data-recourse={RECOURSE_PAGE}>
      <p>{messages.body}</p>
      <ExpandableList list={list} />
      <Button
        text={messages.links.login}
        icon="arrowLeft"
        type="cancel"
        onClick="/"
      />
    </div>
  );
};
