/* commons */
import { createStore } from 'redux';
import { reducers } from '@reducers/index.js';
import { loadState, saveState } from './localStorage.js';

export const store = createStore(reducers, loadState());

store.subscribe(() => {
  saveState({
    user: store.getState().user
  });
});
